<template>
  <div class="container">
    <img class="poster" :src="banner" />
    <div class="registrationTime" v-if="dcode == 'bmtd'">
      <p>报名截止时间为：2022年12月31日<br/>联系人：曲瑞 rqu1@its.jnj.com ; 刘超 liuchao4558120@163.com</p>
    </div>
    <p class="tips" v-if="code == 'csekt'">尊敬的老师，<br/><span style="text-indent:38px;display: inline-block;">非常欢迎您的参与，诚邀您预留以下相关信息，便于我们及时跟您对接详细信息，感谢您的支持！</span></p>
    <div class="content">
      <div class="block">
        <div class="node node_pink">Q</div>
        <div class="box">
          <div class="title">1. 您的姓名</div>
          <div class="input border_pink">
            <input type="text" v-model="Name">
          </div>
        </div>
      </div>
      <div class="block">
        <div class="node node_purple">Q</div>
        <div class="box">
          <div class="title">2. 工作单位</div>
          <div class="input border_purple">
            <input type="text" v-model="Company">
            <div class="HospitalList" v-show="showHospitalPicker">
              <span v-for="(item, index) in HospitalColumns" :key="index" @click="selectHospital(item.HospitalName, item.HospitalID)">{{item.HospitalName}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="block">
        <div class="node node_blue">Q</div>
        <div class="box">
          <div class="title">3. 职称</div>
          <div class="input border_blue">
            <input type="text" v-model="PositionalTitles">
          </div>
        </div>
      </div>
      <div class="block">
        <div class="node node_deongaree">Q</div>
        <div class="box">
          <div class="title">4. 亚专科</div>
          <div class="input border_deongaree">
            <input type="text" v-model="Subspecialty">
          </div>
        </div>
      </div>
      <div class="block">
        <div class="node node_pink">Q</div>
        <div class="box">
          <div class="title">5. 手机号码</div>
          <div class="input border_pink">
            <input type="text" v-model="Phone">
          </div>
        </div>
      </div>
      <div class="block">
        <div class="node node_purple">Q</div>
        <div class="box">
          <div class="title">6. 邮箱</div>
          <div class="input border_purple">
            <input type="text" v-model="Email">
          </div>
        </div>
      </div>
    </div>
    <div class="declare" @click="checkedDeclare">
      <img :src="isCheckedDeclare ? require('@/assets/images/system-complete@2x.png') : require('@/assets/images/dis_system_complete_icon@2x.png')"/>
      <span>若您希望进一步参与与本平台直播内容相关的讨论及互动，请您提供以上个人信息字段。强生承诺仅为上述目的收集和处理您提供的以上个人信息字段并将按照相关法律法规的要求保护您的个人信息。您已明确知晓和同意向强生提供和由强生处理以上个人信息。您同意仅提供本人信息，不会提供他人的个人信息。</span>
    </div>
    <div class="btn_box">
      <div class="btn back" @click="goback">返回</div>
      <div class="btn submit" @click="submit">提交</div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

const delay = (function() {
 let timer = 0;
 return function(callback, ms) {
  clearTimeout(timer);
    timer = setTimeout(callback, ms);
  };
})();

export default {
  name: "Enroll",
  data() {
    return {
      banner: "",
      Name: '',
      Company: '',
      PositionalTitles: '',
      Subspecialty: '',
      Phone: '',
      Email: '',
      showHospitalPicker: false,
      HospitalColumns: [],
      SelectHospital: '',
      isCheckedDeclare: false
    }
  },
  computed: {
    ...mapState(["userInfo"]),
    code() {
      return this.$route.query.code;
    },
    dcode() {
      return this.$route.query.dcode;
    },
    tcode() {
      return this.$route.query.tcode;
    }
  },
  watch: {
    Company(newValue) {
      if (newValue == this.SelectHospital) {
        this.showHospitalPicker = false;
      } else {
        delay(() => {
          this.GetHospital();
        }, 300);
      }
    }
  },
  created() {
    this.Name = this.userInfo.RealName;
    this.getImg();
  },
  methods: {
    async GetHospital() {
      let res = await this.$axios.post('/Api/Api/Web/GetWxUser/GetHospital?HospitalName=' + this.Company);
      let data = res.Data;
      if (data.length > 0) {
        this.showHospitalPicker = true;
        this.HospitalColumns = data;
      } else {
        this.showHospitalPicker = false;
        this.HospitalColumns = [];
      }
    },
    getImg() {
      this.$axios.get('/Api/api/Web/Article/GetImg?sCode=' + this.dcode).then(res => {
        if(res.Data) {
          this.banner = res.Data.File_Url;
        }
      })
    },
    goback() {
      this.$router.go(-1);
    },
    selectHospital(HospitalName, HospitalID) {
      this.Company = HospitalName;
      this.SelectHospital = HospitalName;
      this.showHospitalPicker = false;
    },
    checkedDeclare() {
      this.isCheckedDeclare = !this.isCheckedDeclare;
    },
    submit() {
      if (!this.Name) {
        this.$toast('请输入您的姓名！');
        return false;
      }

      if (!this.Company) {
        this.$toast('请输入工作单位！');
        return false;
      }

      if (!this.PositionalTitles) {
        this.$toast('请输入职称！');
        return false;
      }

      if (!this.Subspecialty) {
        this.$toast('请输入亚专科！');
        return false;
      }

      if (!this.Phone) {
        this.$toast('请输入手机号码！');
        return false;
      }

      if (!this.Email) {
        this.$toast('请输入邮箱！');
        return false;
      }

      if (!this.isCheckedDeclare) {
        this.$toast("请勾选协议！");
        return false;
      }

      this.$axios.post("/Api/Api/Web/Article/AddEnroll", {
        SubjectCode: this.dcode,
        Name: this.Name,
        Company: this.Company,
        PositionalTitles: this.PositionalTitles,
        Subspecialty: this.Subspecialty,
        Phone: this.Phone,
        Email: this.Email
      }).then(res => {
        if(this.code == 'csekt') {
          this.$dialog.alert({
            message: "再次感谢您的参与<br/>我们会尽快与您取得联系！",
          });
        } else {
          this.$dialog.alert({
            message: "提交成功！",
          });
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  min-height: 100%;
  background-color: #F9F9F9;
  .poster {
    display: block;
    width: 100%;
  }
  .registrationTime {
    background: #380808;
    font-size: 11px;
    color: rgba(255, 255, 255, 0.7);
    margin-top: -1px;
    text-align: center;
    line-height: 18px;
    padding: 6px 0;
  }
  .tips {
    font-size: 14px;
    margin: 20px 12px 0 12px;
    color: #a7a7a7;
  }
  .content {
    margin: 20px 12px 20px 24px;
    border-left: 1px dashed #a7a7a7;
    .block {
      position: relative;
      .node {
        position: absolute;
        top: 0;
        left: -12px;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        font-size: 15px;
        color: #FFFFFF;
        text-align: center;
        line-height: 25px;
      }
      .node_pink {
        background: #EC2B89;
      }
      .node_purple {
        background: #AD5EE3;
      }
      .node_blue {
        background: #5F88F9;
      }
      .node_deongaree {
        background: #561EC7;
      }
      .box {
        position: relative;
        margin-left: 24px;
        padding: 20px 40px;
        border-bottom-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.1);
        background-color: #FFFFFF;
        .title {
          position: relative;
          font-size: 15px;
          color: #333333;
        }
        .title::before {
          content: '*';
          position: absolute;
          top: 0;
          left: -10px;
          font-size: 20px;
          color: #CA001B;
        }
        .input {
          position: relative;
          width: 100%;
          height: 36px;
          border-radius: 5px;
          margin-top: 15px;
          input {
            width: 100%;
            height: 100%;
            border: none;
            padding: 0 10px;
            box-sizing: border-box;
            color: #333333;
            font-size: 14px;
            border-radius: 5px;
          }
        }
        .border_pink {
          border: 1px solid rgba(236, 43, 137, 0.3);
        }
        .border_purple {
          border: 1px solid rgba(173, 94, 227, 0.3)
        }
        .border_blue {
          border: 1px solid rgba(95, 136, 249, 0.3);
        }
        .border_deongaree {
          border: 1px solid rgba(86, 30, 199, 0.3);
        }
        .HospitalList {
          position: absolute;
          top: 45px;
          left: 0;
          z-index: 9;
          width: 100%;
          max-height: 200px;
          background-color: #FFFFFF;
          border-radius: 8px;
          box-shadow: 0 8px 12px #ebedf0;
          overflow-y: scroll;
          span {
            padding: 5px 10px;
            display: block;
            font-size: 12px;
            line-height: 24px;
          }
          span:not(:last-child) {
            border-bottom: 1px solid #ebebeb;
          }
        }
      }
    }
    .block:not(:last-child) {
      margin-bottom: 20px;
    }
  }
  .declare {
    display: flex;
    font-size: 11px;
    color: #666666;
    padding: 0 10px;
    margin-top: 15px;
    img {
      display: block;
      width: 14px;
      height: 14px;
      margin: 2px 5px 0 10px;
    }
  }
  .btn_box {
    display: flex;
    padding: 20px 12px 40px;
    .btn {
      flex: 1;
      height: 50px;
      line-height: 50px;
      text-align: center;
      border-radius: 4px;
      font-size: 16px;
    }
    .back {
      border: 1px solid rgba(202, 0, 27, 0.5);
      color: #CA001B;
      box-sizing: border-box;
      margin-right: 12px;
    }
    .submit {
      background-color: #CA001B;
      color: #FFFFFF;
    }
  }
}
</style>